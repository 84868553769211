import { request } from './_base'

export const getUpdateInfo = async () => {
  return await request({
    isPrefix: false,
    url: `https://dl.feisuxia.com/release/release.json`,
    withCredentials: false,
  })
}

export const checkClient = async () => {
  return await request({
    isPrefix: false,
    url: `http://127.0.0.1:30535`,
    withCredentials: false,
  })
}