import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    isLogin: false,
    userinfo: {},
    quantity: {},
    updateInfo: {},
    payDialogShow: false,
    loginDialogShow: false,
    uploadDialogShow: false,
    quantityDialogShow: false,
    downloadDialogShow: false,
    filter: {
      show: false,
      time: -1,
      type: -1,
      size: -1,
      keyword: '',
      searchtype : -1
    }
  },
  getters: {
    allQuantity (state) {
      let quantity = 0
      if (state.quantity.packages) {
        state.quantity.packages.map((item) => {
          quantity = quantity + item.quantity - item.usedQuantity
        })
      }
  
      if (state.quantity.cacheQuantity) {
        quantity = quantity - state.quantity.cacheQuantity
      }
      if (quantity < 0) { quantity = 0 }
      return quantity
    },
    quantityPackages (state) {
      return state.quantity.packages || []
    },
    blockQuantity (state) {
      return state.quantity.blockQuantity || 0
    },
    cacheQuantity (state) {
      return state.quantity.cacheQuantity || 0
    },
  },
  mutations: {
    updateToken (state, data) {
      state.token = data
    },
    updateLogin (state, data) {
      state.isLogin = data
    },
    updateUserinfo (state, data) {
      state.userinfo = data
    },
    updateQuantity (state, data) {
      state.quantity = data
    },
    updatePayDialog (state, data) {
      state.payDialogShow = data
    },
    updateLoginDialog (state, data) {
      state.loginDialogShow = data
    },
    updateUploadDialog (state, data) {
      state.uploadDialogShow = data
    },
    updateQuantityDialog (state, data) {
      state.quantityDialogShow = data
    },
    updateDownloadDialog (state, data) {
      state.downloadDialogShow = data
    },
    setUpdateInfo (state, data) {
      state.updateInfo = data
    },
    changeFilter (state, data) {
      state.filter = Object.assign(state.filter, data)
    },
  },
  actions: {
  },
  modules: {
  }
})
