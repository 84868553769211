import Vue from 'vue'
import { 
  Tree,
  Input,
  Button, 
  Tooltip,
  Message,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Upload,
  Popover,
  Tabs,
  TabPane,
  Pagination,
  Loading,
} from 'element-ui'

import '@/scss/element.scss';

Vue.use(Tree)
Vue.use(Input)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Avatar)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Loading)

Vue.prototype.$message = function(msg) {
 Message({ ...msg, duration: 1000 })
}

Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1500
  })
}

Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1500
  })
}

global.$message = Vue.prototype.$message
global.$message.error = Vue.prototype.$message.error
global.$message.success = Vue.prototype.$message.success