<template>
  <el-dialog 
    :visible.sync="showDialog" 
    :close-on-click-modal='false' 
    width="540px" 
    :before-close="handleDialogClose"
  >
    <div class="content-wrap">
      <h2 class="dialog-title">新建离线下载任务<span class="tip">（你当前剩余流量{{allQuantity | bytesToSize}}）</span> </h2>
      <el-tabs v-model="activeTab" @tab-click="resetUploader">
        <el-tab-pane label="链接文件" name="link">
          <div class="step-wrap" v-if="step == 1">
            <el-input 
              v-model.trim='linkInput' 
              placeholder="支持http链接/BD链接/磁力链接"
            >
              <template slot="prepend" class="prepend">链接地址</template>
            </el-input>
            <el-input 
              v-model.trim='linkPwd' 
              type="text"
              placeholder="下载的链接需要提取码时填写"
            >
              <template slot="prepend">提取码</template>
            </el-input>
            <p class="intro">
              1、预加速文件将根据文件大小预付等额流量，如果加速失败流量将自动返还。<br>
              2、预加速需要一定加速时间，加速完成将第一时间通过微信服务号通知您。
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="BT种子" name="torrent">
          <template v-if="step == 1">
            <el-upload 
              ref="upload" 
              class="upload-torrent" 
              drag 
              action="" 
              :on-change="getTorrent" 
              :multiple="false"
              :auto-upload="false"
              :limit="1"
              :on-remove="removeFile"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <p class="intro">
              1、预加速文件将根据文件大小预付等额流量，如果加速失败流量将自动返还。<br>
              2、预加速需要一定加速时间，加速完成将第一时间通过微信服务号通知您。
            </p>
          </template>
          
        </el-tab-pane>
        <div class="step-wrap" v-if="step == 2">
          <div class="file-preview">
            <el-tree 
              ref="tree"
              show-checkbox
              :data="filelist" 
              node-key="label"
              default-expand-all
              check-on-click-node
              @check="handleNodeClick"
              @node-click="handleNodeClick"
            >
              <p class="custom-tree-node" slot-scope="{ node, data }">
                <span class="wrap">
                  <img class="icon" :src="require(`@/assets/filetype/${data.filetype}.png`)" alt="">
                  <span class="name" :title="node.label">{{ node.label }}</span>
                </span>
                <span class="filesize">{{data.size | bytesToSize }}</span>
              </p>
            </el-tree>
          </div>
          <p class="intro">已选中{{choosedFileList.length}}个文件，共{{choosedQuantity | bytesToSize}}流量。</p>
        </div>
        <div class="dialog-footer">
          <el-button v-if="step == 1" size="small" @click="handleDialogClose">取 消</el-button>
            <el-button 
              v-if="step == 1 && activeTab == 'link'" 
              size="small" type="primary" 
              :disabled="isButtonDisable" 
              @click="createLinkTask"
            >
              {{ parsing ? '正在解析链接...' : '解析链接内容' }}
            </el-button>
            <el-button 
              v-if="step == 1 && activeTab == 'torrent'"
              size="small" type="primary" 
              :disabled="isButtonDisable" 
              @click="createTorrentTask"
            >
              {{ parsing ? '正在解析种子...' : '解析种子内容' }}
            </el-button>
          <el-button v-if="step == 2" size="small" @click="step = 1">上 一 步</el-button>
          <el-button v-if="step == 2" size="small" type="primary" 
            :disabled="choosedFileList.length == 0 || uploading" @click="submitTask"
          >
            云端加速
          </el-button>
        </div>
      </el-tabs>
    </div>
    
  </el-dialog>
</template>

<script>
import { 
  getBase64,
  getFileType,
  parseLocationSearchString,
} from '@/util'
import { parseLink, addTask, parseTorrent } from '@/api/user'

export default {
  data () {
    return {
      step: 1,
      showDialog: true,
      activeTab: 'link',
      linkInput: '',
      linkPwd: '',
      torrentInput: '',
      uploading: false,
      parsing: false,
      filelist: [],
      choosedFileList: [],
    }
  },
  created () {
    if (global.linkInput) {
      this.linkInput = global.linkInput
      this.linkPwd = global.linkPwd
      delete global.linkInput
      delete global.linkPwd
    }
  },
  computed: {
    linkInputFormat () {
      if (this.linkInput.lastIndexOf('pan.baidu.com/share/init?surl=') > -1) {
        const url = new URL(this.linkInput)
        const obj = parseLocationSearchString(url.search)
        const surl = obj.surl
        return `${url.origin}/s/1${surl}`
      } 
      return this.linkInput.trim()
    },
    isButtonDisable () {
      return !(this.linkInput.trim() || this.torrentInput) || this.uploading || this.parsing
    },
    choosedQuantity () {
      let quantity = 0
      this.choosedFileList.forEach(item => {
        quantity = item.size + quantity
      })
      return quantity
    },
    selectedIndex () {
      return this.choosedFileList.map(item => {
        return item.index
      })
    }
  },
  methods: {
    handleNodeClick() {
      this.choosedFileList = this.$refs.tree.getCheckedNodes(true, false)
    },
    resetUploader () {
      this.step = 1
      this.filelist = []
      this.linkInput = ''
      this.pwd = ''
      this.torrentInput = ''
      global.linkInput = ''
      global.linkPwd = ''
      this.$refs.upload && this.$refs.upload.clearFiles()
    },
    handleDialogClose () {
      this.resetUploader()
      this.$emit('close')
    },
    async removeFile () {
      this.torrentInput = ''
      this.$refs.upload && this.$refs.upload.clearFiles()
    },
    async getTorrent (file, fileList) {
      if(fileList.length > 1){
        fileList.splice(0, 1)
      }
      const content = await getBase64(file.raw)
      this.torrentInput = content
    },
    async createLinkTask () {
      if (this.parsing) return
      this.parsing = true
      const res = await parseLink({ url: this.linkInputFormat, pwd: this.linkPwd})
      this.parsing = false
      if (res.code == 0) {
        this.filelist = this.parseFileTree(res.data)
        this.step = 2
      } else {
        this.error = true
        this.$message.error('解析链接失败')
      }
    },
    async createTorrentTask () {
      if (this.parsing) return
      this.parsing = true
      const res = await parseTorrent({ content: this.torrentInput })
      if (res.code == 0) {
        this.linkInput = res.data
        const res2 = await parseLink({ url: this.linkInput, pwd: '' })
        this.parsing = false
        if (res2.code == 0) {
          this.filelist = this.parseFileTree(res2.data)
          this.step = 2
        } else {
          this.error = true
          this.$message.error('解析种子文件失败')
        }
      } else {
        this.error = true
        this.$message.error('解析种子文件失败')
      }
    },
    async submitTask () {
      if (this.selectedIndex.length > 30) {
        return this.$message.error('一次提交不要超过 30 个任务')
      }
      if (this.uploading) return 
      this.uploading = true
      const res = await addTask({
        url: this.linkInputFormat, 
        pwd: this.linkPwd,
        index: this.selectedIndex.join(',')
      })
      if (res.code == 0) {
        this.$emit('success')
        this.$message.success('提交成功')
        this.handleDialogClose()
      } else if (res.code == 511001) {
        this.$message.error('流量不足，请充值')
      } else {
        this.$message.error('请求出错，稍后重试')
      }
      this.uploading = false
    },
    parseFileTree (arr) {
      if (arr && arr.length > 0) {
        let treeDTO = []
        arr.forEach((item, index) => {
          let children = treeDTO
          let nodeArray = item.Path.replace('/', '').split('/')
          for (let i = 0; i < nodeArray.length; i++) {
            
            let key = nodeArray[i]
            let isExistKey = false
            if (i == nodeArray.length - 1) {
              return children.push({
                label: key,
                size: item.Size,
                children: [],
                index: index,
                filetype: getFileType(key)
              })
            }
            for (let j = 0; j < children.length; j++) {
              let childItem = children[j]
              if (childItem.label == key) {
                isExistKey = true
                childItem.size = childItem.size + item.Size
                children = childItem.children
                break
              }
            }
            if (!isExistKey) {
              let temp = {
                label: key,
                size: item.Size,
                children: [],
                filetype: 'filetype_folder'
              }
              children.push(temp)
              children = temp.children
            }
          }
        })
        let temp
        while(treeDTO.length == 1) {
          temp = treeDTO
          treeDTO = treeDTO[0].children
        }
        return temp
      }
    },
  } 
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.content-wrap {
  padding: 20px 30px 0;
  .dialog-title {
    margin-bottom: 10px;
    font-weight: 400;
    color: #333;
    .tip {
      font-size: 14px;
      color: $theme-color;
    }
  }
  .el-input {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .file-preview {
    height: 170px;
    overflow: auto;
    border: 1px solid #eee;
    border-radius: 4px;
    &::-webkit-scrollbar-track {
      width: 4px;
      border-radius: 4px;
      background-color: #f8f8f8;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 3px;
      background: #999;
    }
  }
  .intro {
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
  }
  .download-info {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      width: 75px;
      flex-shrink: 0;
      font-weight: bold;
    }
    .content {
      flex: auto;
      color: #666;
      font-size: 13px;
    }
    .choose {
      width: 70px;
      text-align: right;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .upload-torrent {
    text-align: center;
  }
  .el-icon-upload {
    margin: 15px auto 5px;
    font-size: 40px;
    line-height: 40px;
  }
  .el-upload__text {
    font-size: 12px;
  }
  .dialog-footer {
    padding: 15px 0;
    text-align: right;
  }
}
.custom-tree-node {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  flex: auto;
  .icon {
    width: 14px;
    margin-right: 5px;
    flex-shrink: 0;
  }
  .filesize {
    text-align: right;
    width: 75px;
    margin-left: 20px;
    padding-right: 15px;
    flex-shrink: 0;
  }
  .wrap {
    display: flex;
    align-items: center;
  }
  .name {
    display: inline-block;
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 280px;
  }
}

</style>