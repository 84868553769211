<template>
  <el-dialog
    width="500px"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div class="content">
      <h2>高速流量购买</h2>
      <p class="tip">高速流量仅预加速文件或高速下载时使用，失败会全额退还，请放心购买</p>
      <div class="package-wrap">
        <div 
          class="package-item" 
          v-for="item in packages" 
          :key="item.pid"
          :class="{'active': pack.pid == item.pid}"
          @click="choosePackage(item)"
        >
          <label class="extra" v-if="item.extra">{{item.extra}}</label>
          <p class="quantity">{{item.quantity | bytesToSize }}</p>
          <p class="gift" v-if="item.giftQuantity">加赠:{{item.giftQuantity | bytesToSize }}</p>
          <p class="duration">有效期:{{item.duration }}天</p>
          <p class="price">
            ￥<span class="first">{{`${item.price}`.split('.')[0]}}</span>.<span class="second">{{`${item.price}`.split('.')[1] || '00'}}</span> 
          </p>
        </div>
      </div>
      <div class="pay-type">
        <p class="title">支付方式</p>
        <div class="channel-wrap">
          <div 
            class="channel-item" 
            v-for="item in channels" 
            :key="item"
            :class="{'active': item == paytype}"
            @click="choosePayType(item)"
          >
            <template v-if="item == 'alipay'">
              <img src="https://res.oodcd.cn/icons/alipay.png" class="icon" >
              支付宝支付
            </template>
            <template v-if="item == 'wxpay'">
              <img src="https://res.oodcd.cn/icons/wxpay.png" class="icon" >
              微信支付
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p class="tip">
        <span class="label">实付金额：</span>
        <span class="theme">{{pack.price}}元/{{ (pack.quantity + pack.giftQuantity) | bytesToSize }}流量
          (包含加赠的{{pack.giftQuantity | bytesToSize }})
        </span>
      </p>
      <span class="pay-button" @click="createOrder">立即支付</span>
    </div>
    <el-dialog
      width="320px"
      :visible.sync="qrcodeShow"
      :close-on-click-modal="false"
      :before-close="handleQrcodeClose"
      append-to-body
    >
      <div class="qrcode-wrap">
        <div id="qrcode" ref="qrcode" class="qrcode"></div>
        <p class="tip">请使用微信扫码支付</p>
        <div class="button-wrap">
          <span class="button cancel" @click="handleQrcodeClose">取消支付</span>
          <span class="button confirm" @click="confirmPay">我已支付</span>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
/* global QRCode */
import { 
  getPayPackages,
  getPayChannels,
  createOrder,
  queryOrder
} from '@/api/pay'

export default { 
  data () {
    return {
      pack: {},
      paytype: 'wxpay',
      show: true,
      qrcodeShow: false,
      packages: [],
      channels: [],
      timer: null,
      QRCode: null,
      payurl: '',
      tradeno: ''
    }
  },
  async mounted() {
    const [ packages, channels ] = await Promise.all([
      getPayPackages(),
      getPayChannels(),
    ]) 
    this.packages = packages.data.sort((a, b) => {
      return a.price - b.price
    })
    this.channels = channels.data
    
    this.choosePayType(this.channels[0])
    this.choosePackage(this.packages[0])
  },
  destroyed () {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleQrcodeClose () {
      this.qrcodeShow = false
      this.timer && clearInterval(this.timer)
    },
    choosePackage(pack) {
      this.pack = pack
    },
    choosePayType(type) {
      this.paytype = type
    },
    async generateQrcode (text) {
      if (this.QRCode) {
        this.QRCode.clear()
        this.QRCode.makeCode(text)
      } else {
        const qrcode_timer = setInterval(() => {
          if (this.$refs.qrcode) {
            this.QRCode = new QRCode('qrcode', {
              width: 200,
              height: 200, 
              text,
              correctLevel : QRCode.CorrectLevel.H
            })
            clearInterval(qrcode_timer)
          }
        }, 100)
      }
    },
    async createOrder () {
      const res = await createOrder({ pid: this.pack.pid, paytype: this.paytype })
      if (res.code == this.CODE.success) {
        // 下一步显示二维码
        const { tradeno, url } = res.data
        this.tradeno = tradeno
        this.payurl = url
        this.qrcodeShow = true
        this.generateQrcode(this.payurl)
        await this.queryOrder()
      } else if (
        res.code == this.CODE.genarate_order_error || 
        res.code == this.CODE.genarate_order_error
      ) {
        return this.$message.error(this.MESSAGE.GENERATE_ORDER_ERROR)
      } else {
        return this.$message.error(this.MESSAGE.UNKNOWN_ERROR)
      }
    },
    async queryOrder () {
      this.timer = setInterval(async () => {
        const res = await queryOrder({ tradeno: this.tradeno })
        if (res.code == this.CODE.success) {
          this.$emit('success')
          this.handleQrcodeClose()
          this.$message.success('付款成功')
          this.handleClose()
        }
      }, 1600)
    },
    async confirmPay () {
      const res = await queryOrder({ tradeno: this.tradeno })
      if (res.code == this.CODE.success) {
        this.$emit('success')
        this.handleQrcodeClose()
        this.$message.success('付款成功')
        this.handleClose()
      } else {
        this.$message.error('尚未检测到支付')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
  .content {
    padding: 30px 30px 20px;
    text-align: center;
    h2 {
      font-size: 18px;
    }
    .tip {
      margin-top: 10px;
      font-size: 13px;
      margin-bottom: 20px;
    }
    .package-wrap {
      display: flex;
      justify-content: space-between;
      .package-item {
        cursor: pointer;
        padding: 10px;
        padding-top: 22px;
        text-align: center;
        width: 30%;
        border: 2px solid #eee;
        border-radius: 4px;
        transition:border ease 0.1s;
        position: relative;
        &:hover, &.active {
          border-color: $danger-color;
        }
      }
      .quantity {
        font-size: 25px;
        font-weight: 400;
      }
      .gift {
        font-size: 13px;
        margin-bottom: 6px;
      }
      .duration {
        color: #333;
      }
      .price {
        margin-top: 5px;
        .first {
          font-size: 20px;
        }
        .second {
          font-weight: bold;
        }
      }
      .extra {
        position: absolute;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        color: #fff;
        background-color: $danger-color;
        top: -1px;
        right: -1px;
        font-size: 12px;
        padding: 2px 4px;
      }
    }
    .pay-type {
      margin-top: 10px;
      text-align: left;
      .title {
        font-weight: #333;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .channel-wrap {
        display: flex;
      }
      .channel-item {
        padding: 6px 10px;
        cursor: pointer;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        margin-right: 10px;
        border-radius: 4px;
        font-size: 13px;
        &:hover, &.active {
          border-color: $danger-color;
        }
        .icon {
          height: 18px;
          margin-right: 4px;
        }
      }
    }
  }
  .footer {
    padding: 10px 15px;
    background-color: rgba($theme-color, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tip {
      font-size: 13px;
      font-weight: bold;
    }
    .pay-button {
      border-radius: 4px;
      padding: 6px 20px;
      font-size: 13px;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: all ease 0.2s;
      background-color: lighten($theme-color, 0);
      &:hover {
        background-color: darken($theme-color, 5%);
      }
      &:active {
        background-color: darken($theme-color, 10%);
      }
    }
  }
  .qrcode-wrap {
    padding-top: 30px;
    padding-bottom: 15px;
    text-align: center;
    width: 300px;
    margin: 0 auto;
    .qrcode {
      padding: 15px;
      border: 1px solid #eee;
      border-radius: 4px;
      display: inline-block;
    }
    .tip {
      font-size: 12px;
      margin-top: 5px;
    }
    .button-wrap {
      margin-top: 15px;
      text-align: center;
      .button {
        display: inline-block;
        border-radius: 4px;
        padding: 6px 20px;
        font-size: 13px;
        cursor: pointer;
        transition: all ease 0.2s;
      }
      .confirm {
        color: #fff;
        background-color: lighten($theme-color, 0);
        &:hover {
          background-color: darken($theme-color, 5%);
        }
        &:active {
          background-color: darken($theme-color, 10%);
        }
      }
      .cancel {
        margin-right: 20px;
        color: #666;
        border: 1px solid #ddd;
        &:hover {
          background-color: #eee;
        }
        &:active {
          background-color: #e3e3e3;
        }
      }
    }
  }
</style>