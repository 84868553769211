import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { isMobile } from '@/util'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/Disk.vue'),
    children: [
      {
        path: 'task',
        name: 'task',
        meta: {
          requiredAuth: true,
        },
        component: () => import('@/views/Task.vue')
      },
      {
        path: 'downloading',
        name: 'downloading',
        meta: {
          requiredAuth: true,
        },
        component: () => import('@/views/Download.vue')
      },
      {
        path: 'downloaded',
        name: 'downloaded',
        meta: {
          requiredAuth: true,
        },
        component: () => import('@/views/Download.vue')
      },
      {
        path: 'pc',
        name: 'pc',
        meta: {
          requiredAuth: true,
        },
        component: () => import('@/views/Download.vue')
      },
      {
        path: 'search',
        name: 'search',
        meta: {
          requiredAuth: true,
        },
        component: () => import('@/views/Search.vue')
      },
    ]
  },
  
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (isMobile()) {
    const hash = window.location.hash
    if (hash.includes('?')) {
      const arr = hash.split('?')
      window.location.href = `https://m.feisuxia.com/#/?${arr[1]}`
    } else {
      window.location.href = `https://m.feisuxia.com/#/`
    }
  }

  const token = localStorage.getItem('token')
  global.token = token  
  if (to.meta.requiredAuth && !global.token) {
    next('/')
  } 

  if (to.name == 'search') {
    const { filter } = store.state
    const query = to.query
    if (filter.time !== -1 || filter.size !== -1 || filter.type !== -1 || filter.searchtype !==-1 || filter.keyword !=  '') {
      if (query.size !== undefined || query.time !== undefined || query.type !== undefined || query.searchtype !== undefined || query.keyword !== undefined ) {
        next()
      } else {
        if (filter.size !== -1) {
          query.size = filter.size
        }
        if (filter.time !== -1) {
          query.time = filter.time
        }
        if (filter.type !== -1) {
          query.type = filter.type
        }
        if (filter.searchtype !== -1) {
          query.searchtype = filter.searchtype
        }
        if (filter.keyword !== '') {
          query.keyword = filter.keyword
        }
        next({ path: 'search', query })
      }
    } else {
      next()
    }
  }
  next()
})

export default router
