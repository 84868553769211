<template>
  <div id="app-wrap" @click="hideFilter">
    <router-view id="app" />
    <download-dialog v-if="downloadDialogShow" />
    <login-dialog @close="hideLoginDialog" v-if="loginDialogShow" />
    <quantity-dialog @close="hideQuantityDialog" v-if="quantityDialogShow" />
    <pay-dialog @close="hidePayDialog" @success="paySuccessCallback" v-if="payDialogShow" />
    <upload-dialog @close="hideUploadDialog" @success="uploadSuccessCallback" v-if="uploadDialogShow" />
  </div>
</template>

<script>
import { getUpdateInfo } from '@/api/config'
import PayDialog from '@/components/Pay'
import LoginDialog from '@/components/Login'
import UploadDialog from '@/components/Uploader'
import QuantityDialog from '@/components/Quantity'
import DownloadDialog from '@/components/Download'
export default {
  data () {
    return {
    }
  },
  components: { PayDialog, LoginDialog, UploadDialog, QuantityDialog, DownloadDialog },
  async mounted () {
    const data = await getUpdateInfo()
    if (data) {
      this.$store.commit('setUpdateInfo', data)
    }

    await Promise.all([
      this.updateUserinfo(),
      this.updateQuantity()
    ])

    setTimeout(() => {
      this.checkAutoUpload()
    }, 100)
  },
  methods: {
    async paySuccessCallback () {
      await this.updateUserinfo()
    },
    uploadSuccessCallback () {
      if (this.$route.name == 'task') {
        this.$router.go(0)
      } else {
        this.$router.push('/app/task')
      }
    },
    hideFilter () {
      this.$store.commit('changeFilter', { show: false })
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/_base.scss';
#app-wrap, #app {
  height: 100%;
  background-color: #f8f8f8;
}

#app {
  overflow-y: auto;
}
</style>
