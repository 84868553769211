import axios from 'axios'
// const APIHOST = 'http://localhost:6002'
const APIHOST = 'https://user.krzb.net'

export const request = async ({ method ='GET', data, url, params, headers = {}, isPrefix = true, withCredentials = true }) => {
  try {
    const res = await axios({
      method,
      url: isPrefix ? `${APIHOST}${url}` : url,
      data,
      params: {
        plt: 'web',
        appv: '1.0.0',
        t: new Date().getTime(),
        ...params
      },
      timeout: 20 * 1000,
      headers: {
        "Cache-Control": "no-cache",
        ...headers
      },
      withCredentials,
    })
    return res.data
  } catch (err) {
    console.log(`request error: ${err}`)
    if (err.response) {
      return err.response.data
    } else {
      return null
    }
  }
}