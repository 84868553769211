export const FILE_TYPE_VIDEO = 'filetype_video'
export const FILE_TYPE_AUDIO = 'filetype_audio'
export const FILE_TYPE_BT = 'filetype_bt'
export const FILE_TYPE_PPT = 'filetype_ppt'
export const FILE_TYPE_XLS = 'filetype_xls'
export const FILE_TYPE_DOC = 'filetype_doc'
export const FILE_TYPE_PDF = 'filetype_pdf'
export const FILE_TYPE_ZIP = 'filetype_zip'
export const FILE_TYPE_IMG = 'filetype_img'
export const FILE_TYPE_OTHERS = 'filetype_others'
export const FILE_TYPE_UNKNOWN = 'filetype_unknown'
export const FILE_TYPE_FOLDER = 'filetype_folder'