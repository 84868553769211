export const EMPTY_STRING = ''

export const OFFLINE_TASK_STATUS = {
  WAITING_TORRENT: -1,
  CREATED: 1,
  WAITING: 2,
  DOWNLOADING: 3,
  FINISHED: 4,
  FAILED: 5,
  CANCELED: 6,
}

export const DOWNLOAD_STATUS = {
  ERROR: 'error',
  ACTIVE: 'active',
  PAUSED: 'paused',
  COMPLETE: 'complete',
}

export const FILTER = {
  type: {
    7: {
        name: 'BT种子',
        value: 7
      },
    6: {
        name: '压缩包',
        value: 6
      },
    5: {
        name: '软件',
        value: 5
     },
    4: {
        name: '文档',
        value: 4
     },
    3: {
        name: '图片',
        value: 3
     },
    2: {
        name: '音乐',
        value: 2
     },
    1: {
        name: '视频',
        value: 1
     },
    0: {
        name: '文件夹',
        value: 0
     }
  },
  time: {
    3: {
      name: '最近一年',
      value: 365
    },
    2: {
      name: '最近半年',
      value: 180
    },
    1: {
      name: '最近一月',
      value: 30
    },
    0: {
      name: '最近一周',
      value: 7
    }
  },
  size: {
    3: {
      name: '大于2GB',
      minSize: 2147483648
    },
    2: {
      name: '200MB-2GB',
      maxSize: 2147483648,
      minSize: 209715200
    },
    1: {
      name: '20MB-200MB',
      maxSize: 209715200,
      minSize: 20971520
    },
    0: {
      name: '小于20MB',
      maxSize: 20971520,
    }
  },
  searchtype: {
    1: {
      name: '模糊搜索',
      value: 'match'
    },
    0: {
      name: '精确搜索',
      value: 'precise'
    }
  }
}