<template>
  <el-dialog
    width="500px"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div class="content">
      <h3>流量包明细</h3>
      <div class="package-wrap">
        <div class="package-item header">
          <span class="index">序号</span>
          <span class="quantity">总流量</span>
          <span class="quantity">已使用</span>
          <span class="date">到期时间</span>
        </div>
        <div class="package-item" v-for="(item, index) in quantityPackages" :key="index">
          <span class="index">{{index + 1}}</span>
          <span class="quantity">{{item.quantity | bytesToSize}}</span>
          <span class="quantity">{{item.usedQuantity | bytesToSize}}</span>
          <span class="date" :class="{'danger': item.expired, 'success': !item.expired }" >{{item.expiredTime | formatDate}}</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
export default { 
  data () {
    return {
      show: true,
    }
  },
  computed: {
    parsedPackages () {
      return this.quantityPackages.map((item) => {
        return {
          quantity: item.quantity,
          usedQuantity: item.usedQuantity,
          expiredTime: item.expiredTime,
          expired: dayjs().isAfter(dayjs(item.expiredTime))
        }
      })
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
  .content {
    padding: 20px 30px;
    text-align: center;
    h3 {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .package-wrap {
      height: 300px;
      overflow: auto;
      border: 1px solid #eee;
      border-radius: 4px;
      &::-webkit-scrollbar-track {
        width: 4px;
        border-radius: 4px;
        background-color: #f8f8f8;
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 3px;
        background: #999;
      }
      .package-item {
       padding: 10px 5px;
       display: flex;
       color: #666;
       font-size: 12px;
       border-top: 1px solid #eee;
       &:first-child {
         border-top: none;
       }
       &.header {
         font-weight: bold;
         color: #333;
       }
       .index {
         width: 40px;
          flex-shrink: 1;
       }
       .quantity {
         width: 120px;
         flex-shrink: 1;
       }
       .date {
         flex: auto;
         font-weight: bold;
       }
      }
    }
  }
</style>