import dayjs from 'dayjs'
import { mapState, mapGetters } from 'vuex'
import CODE from '@/constants/errcode'
import { OFFLINE_TASK_STATUS, DOWNLOAD_STATUS } from '@/constants/common'
import { checkClient } from '@/api/config'
import { getAvailableQuantity, getUserinfo } from '@/api/user'

Array.prototype.remove = function(val) {
  var index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}

const install = (Vue) => {
  Vue.mixin({
    data () {
      return {
        CODE,
        DOWNLOAD_STATUS,
        OFFLINE_TASK_STATUS,
        CHECK_INSTALL_TIMES: 0,
      }
    },
    computed: {
      ...mapState([
        'token',
        'isLogin',
        'userinfo',
        'quantity',
        'updateInfo',
        'payDialogShow',
        'loginDialogShow',
        'uploadDialogShow',
        'quantityDialogShow',
        'downloadDialogShow',
        'filter',
      ]),
      ...mapGetters([
        'allQuantity',
        'quantityPackages'
      ]),
    },
    filters: {
      bytesToSize (bytes) {
        if (bytes < 0) { return '0KB' }
        const b = parseInt(bytes, 10)
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
        if (isNaN(b)) { return '0KB' }
        if (b === 0) { return '0KB' }
        const i = parseInt(Math.floor(Math.log(b) / Math.log(1024)), 10)
        if (i === 0) { return `${b}${sizes[i]}` }
        if (i > 1) {
          return `${(b / (1024 ** i)).toFixed(1)}${sizes[i]}`
        } else {
          return `${(b / (1024 ** i)).toFixed(0)}${sizes[i]}`
        }
      },
      formatDate (value) {
        return dayjs(value).format('YYYY/MM/DD HH:mm')
      }
    },
    methods: {
      showLoginDialog () {
        this.$store.commit('updateLoginDialog', true)
      },
      hideLoginDialog () {
        this.$store.commit('updateLoginDialog', false)
      },
      showPayDialog () {
        this.$store.commit('updatePayDialog', true)
      },
      hidePayDialog () {
        this.$store.commit('updatePayDialog', false)
      },
      showUploadDialog () {
        this.$store.commit('updateUploadDialog', true)
      },
      hideUploadDialog () {
        this.$store.commit('updateUploadDialog', false)
      },
      showQuantityDialog () {
        this.$store.commit('updateQuantityDialog', true)
      },
      hideQuantityDialog () {
        this.$store.commit('updateQuantityDialog', false)
      },
      showDownloadDialog () {
        this.$store.commit('updateDownloadDialog', true)
      },
      hideDownloadDialog () {
        this.$store.commit('updateDownloadDialog', false)
      },
      async updateUserinfo () {
        const res = await getUserinfo()
        if (res.code == 0) {
          this.$store.commit('updateLogin', true)
          this.$store.commit('updateUserinfo', res.data)
        } else if (res.code == 1001) {
          localStorage.setItem('token', '')
          this.$store.commit('updateToken', '')
          this.$store.commit('updateLogin', false)
          if (this.$route.meta.requiredAuth) {
            this.$router.push('/home')
          }
        }
      },
      async updateQuantity () {
        const res = await getAvailableQuantity()
        if (res.code == 0) {
          this.$store.commit('updateQuantity', res.data)
        }
      },
      checkAutoUpload () {
        const query = this.$route.query
        if (query.url && this.isLogin) {
          global.linkInput = query.url
          global.linkPwd = query.pwd
          this.$router.push('/app/task')
          this.showUploadDialog()
        } if (query.url && !this.isLogin) {
          this.showLoginDialog()
        }
      },
      openWindow (url, target = false) {
        const obj = document.createElement('a')
        if (target) {
          obj.target = '_blank'
        }
        obj.referrerPolicy = 'origin'
        obj.href = url
        obj.click()
      },
      async checkClientInstall (callback) {
        const res = await checkClient()
        if (res) {
          callback && callback()
        } else {
          this.CHECK_INSTALL_TIMES++
          if (this.CHECK_INSTALL_TIMES > 4) {
            this.showDownloadDialog()
            this.CHECK_INSTALL_TIMES = 0
          } else {
            setTimeout(async () => {
              await this.checkClientInstall()
            }, 2000)
          }
        }
      }
    }
  })
}

export default { install }
