export default {
  success:                   0,
  error:                     -1,
  invalid_token:             1001,     // token无效
  invalid_params:            1002,     // 参数错误

  qrcode_not_scan:           2001,     // 没有扫码
  wx_code_error:             2002,     // 微信登录 code 错误              
  no_user:                   2003,     // 用户不存在

  jwt_generate_error:        510004,   // jwt生成错误

  genarate_order_error:      510011,   // 生成支付订单错误
  generate_pay_url_error:    510012,   // 生成支付连接错误
  order_not_exist:           510013,   // 订单不存在
  order_not_paid:            510014,   // 订单未支付

  not_enough_traffic:        511001,   // 没有足够的流量
  consume_traffic_error:     511002,   // 消耗流量出错
  redemption_error:          511003,   // 兑换出错

  get_package_error:         530001,   // package 获取错误
  not_available_packages:    530002,   // 没有可用的套餐
  not_enough_capacity:       530003,   // 没有足够的容量
  consume_quantity_error:    530004,   // 消耗 流量、离线次数、预览次数 失败
}