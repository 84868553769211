import Vue from "vue"
import { 
    Toast, 
    Button,
    Icon,
    Popup,
    Field,
    NavBar,
    Loading,
    Empty,
    Dialog,
} from 'vant'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Field)
Vue.use(NavBar)
Vue.use(Loading)
Vue.use(Empty)
Vue.use(Dialog)
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog